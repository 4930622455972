import { createSlice } from "@reduxjs/toolkit";
// import { getAllStaffAction, insertUpdateStaffAction, staffStatusChangeACTION } from "../../actions/menuActions/menuActions.js";
import { getAllStaffAction, getAllStaffHeaderAction, insertUpdateStaffAction, insertUpdateStaffHeaderAction, staffStatusChangeACTION } from "../../actions/staffActions/staffActions.js";
import { toast } from 'react-toastify';

const INITIAL_STATE = {

    Staff: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    insertUpdateStaffDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    activeInactiveStaffDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    getAllStaffHeader: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    insertUpdateStaffHeaderDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

}

const StaffReducer = createSlice({
    name: "Staff",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetstateStaff(state, action) {
            // state.Staff = INITIAL_STATE.Staff;
            state.insertUpdateStaffDetails = INITIAL_STATE.insertUpdateStaffDetails;
        },
        ResetstateStaffHeader(state, action) {
            state.insertUpdateStaffHeaderDetails = INITIAL_STATE.insertUpdateStaffHeaderDetails;
        },

    },

    extraReducers: (builder) => {
        builder.addCase(getAllStaffAction.fulfilled, (state, action) => {
            state.Staff.data = action.payload.data?.records;
            state.Staff.apiMsg.status = action.meta.requestStatus;
            state.Staff.apiMsg.message = "success";
        });
        builder.addCase(getAllStaffAction.pending, (state, action) => {
            state.Staff.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllStaffAction.rejected, (state, action) => {
            state.Staff.apiMsg.message = action.error.message;
            state.Staff.apiMsg.status = action.meta.requestStatus;
        });


        //insertupdate reducer

        builder.addCase(
            insertUpdateStaffAction.fulfilled,
            (state, action) => {

                state.insertUpdateStaffDetails.data = action.payload.data.data;
                state.insertUpdateStaffDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateStaffDetails.apiMsg.message = action.payload.message.message;

                if (!action.payload.newRecord) {
                }
                else {
                    if (state.Staff.data.records) {
                        state.Staff.data.records.unshift(action.payload.data.data);
                    }
                    else {
                    }
                }
                toast.success(action.payload.message.message, {
                    position: 'bottom-center',
                    toastId: "dept-error"
                });
            }
        );

        builder.addCase(
            insertUpdateStaffAction.pending,
            (state, action) => {
                state.insertUpdateStaffDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateStaffAction.rejected,
            (state, action) => {

                console.log(action.error.message);
                state.insertUpdateStaffDetails.apiMsg.message = action.error.message;
                state.insertUpdateStaffDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: 'bottom-center',
                    toastId: "dept-error"
                });
            }
        );



        builder.addCase(staffStatusChangeACTION.fulfilled, (state, action) => {
            state.activeInactiveStaffDetails.data = action.payload.data;
            state.activeInactiveStaffDetails.apiMsg.status = action.meta.requestStatus;
            state.activeInactiveStaffDetails.apiMsg.message = action.payload.message.message;
            
            toast.success(action.payload.message.message, {
                position: 'bottom-center',
                toastId: "dept-error"
            });

        });

        builder.addCase(staffStatusChangeACTION.pending, (state, action) => {
            state.activeInactiveStaffDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(staffStatusChangeACTION.rejected, (state, action) => {
            state.activeInactiveStaffDetails.apiMsg.message = action.error.message;
            state.activeInactiveStaffDetails.apiMsg.status = action.meta.requestStatus;
        });


        builder.addCase(getAllStaffHeaderAction.fulfilled, (state, action) => {
            state.getAllStaffHeader.data = action.payload.data;
            state.getAllStaffHeader.apiMsg.status = action.meta.requestStatus;
            state.getAllStaffHeader.apiMsg.message = "success";
        });
        builder.addCase(getAllStaffHeaderAction.pending, (state, action) => {
            state.getAllStaffHeader.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllStaffHeaderAction.rejected, (state, action) => {
            state.getAllStaffHeader.apiMsg.message = action.error.message;
            state.getAllStaffHeader.apiMsg.status = action.meta.requestStatus;
        });


        //insert update case

        builder.addCase(
            insertUpdateStaffHeaderAction.fulfilled,
            (state, action) => {

                state.insertUpdateStaffHeaderDetails.data = action.payload.data.data;
                state.insertUpdateStaffHeaderDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateStaffHeaderDetails.apiMsg.message = action.payload.message.message;

                toast.success(action.payload.message.message, {
                    position: 'bottom-center',
                    toastId: "dept-error"
                });

            }
        );

        builder.addCase(
            insertUpdateStaffHeaderAction.pending,
            (state, action) => {
                state.insertUpdateStaffHeaderDetails.apiMsg.status = action.meta.requestStatus;
            }
        );

        builder.addCase(
            insertUpdateStaffHeaderAction.rejected,
            (state, action) => {

                console.log(action.error.message);
                state.insertUpdateStaffHeaderDetails.apiMsg.message = action.error.message;
                state.insertUpdateStaffHeaderDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: 'bottom-center',
                    toastId: "dept-error"
                });
            }
        );

    }
})
export default StaffReducer.reducer;
export const { RESET, ResetstateStaff } = StaffReducer.actions;
