import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../../API";

export const getAllStaffAction = createAsyncThunk(
  "Staff/getAllStaffAction",
  async (data) => {  
    return apiCall("restaurantstaff/getall", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
              data: {
                ...response.data.data,
              },
            };
          } else {
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);


export const insertUpdateStaffAction = createAsyncThunk(
  "Staff/insertUpdateStaffAction",
  async (data) => {

    return apiCall("restaurantstaff/insertupdate", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              data: {
                ...response.data,
              },
              message: {
                code: response.data.message.code,
                type: response.data.message.type,
                message: response.data.message.message
              },
              newRecord: data.id ? false : true
            }
          } else {
            if (!response.data.message && response.data.errors && response.data.errors.length) {
              response.data.message = response.data.errors[0];
            }
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
        else {
          return Promise.reject({
            ...response.data,
          });
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const staffStatusChangeACTION = createAsyncThunk(
  "Staff/staffStatusChangeACTION",
  async (data) => {
    return apiCall("restaurantstaff/changestatus", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
              id: data.id,
            };
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);


export const getAllStaffHeaderAction = createAsyncThunk(
    "Staff/getAllStaffHeaderAction",
    async (data) => {
  
      return apiCall("restaurantstaff/getheader", "POST", data, false)
        .then((response) => {
          if (response.status === 200) {
            if (!response.data.hasError) {
              return {
                ...response.data,
                data: {
                  ...response.data.data
                },
              };
            }
          }
        })
        .catch((error) => {
          return Promise.reject({
            ...error,
          });
        });
    }
  );
  
  
  export const insertUpdateStaffHeaderAction = createAsyncThunk(
    "Staff/insertUpdateStaffHeaderAction",
    async (data) => {
  
      return apiCall("restaurantstaff/insertupdateheader", "POST", data, false)
        .then((response) => {
          if (response.status === 200) {
            if (!response.data.hasError) {
              return {
                data: {
                  ...response.data,
                },
                message: {
                  code: response.data.message.code,
                  type: response.data.message.type,
                  message: response.data.message.message
                },
                newRecord: data.id ? false : true
              }
            } else {
              if (!response.data.message && response.data.errors && response.data.errors.length) {
                response.data.message = response.data.errors[0];
              }
              return Promise.reject({
                ...response.data.message,
              });
            }
          }
          else {
            return Promise.reject({
              ...response.data,
            });
          }
        })
        .catch((error) => {
          return Promise.reject({
            ...error,
          });
        });
    }
  );
  